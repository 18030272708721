var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "MarkPrebookingAsNonConnectedForm",
        attrs: {
          header: { isClosable: true },
          size: _vm.SIZES.small,
          buttons: _vm.modalButtons,
          "data-test-id": "mark_as_non_connected-form",
        },
        scopedSlots: _vm._u([
          {
            key: "alerts",
            fn: function () {
              return [
                _c("StoreNotificationComponent", {
                  attrs: {
                    action: _vm.prebooking.isNonConnected
                      ? "edit information"
                      : "mark as non connected",
                    "store-domain": _vm.DOMAINS_MODEL.carsharing.prebookings,
                    scope: _vm.prebookingScopes.markAsNonConnected,
                    "data-test-id": "alert",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "MuiValidationWrapper",
                  {
                    attrs: {
                      id: "markPrebookingAsNonConnectedForm",
                      "data-test-id": "form",
                    },
                    on: {
                      areAllValid: (valid) => (_vm.isFormValid = valid),
                      submit: _vm.request,
                    },
                  },
                  [
                    !_vm.prebooking.isNonConnected
                      ? _c("p", { staticClass: "mb-4" }, [
                          _vm._v(
                            " After a pre-booking is marked as non-connected the action cannot be undone. "
                          ),
                        ])
                      : _vm._e(),
                    _c("MuiTextarea", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: {
                            isRequired: true,
                            isMinLength: {
                              message: "We need at least 6 characters",
                              length: 6,
                            },
                          },
                          expression:
                            "{\n          isRequired: true,\n          isMinLength: {\n            message: 'We need at least 6 characters',\n            length: 6,\n          }\n        }",
                        },
                      ],
                      attrs: {
                        label: "Information*",
                        name: "comments",
                        placeholder:
                          "Write all the information needed to inform the user about the booking.",
                        rows: "4",
                        "data-test-id": "comment_input",
                      },
                      model: {
                        value: _vm.inputs.comment,
                        callback: function ($$v) {
                          _vm.$set(_vm.inputs, "comment", $$v)
                        },
                        expression: "inputs.comment",
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    ),
    [
      _c("div", {
        attrs: { slot: "header", "data-test-id": "header" },
        domProps: { innerHTML: _vm._s(_vm.modalTitle) },
        slot: "header",
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }