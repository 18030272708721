import isEmpty from 'lodash/isEmpty';
import { RECORD_TYPES } from '@emobg/motion-ui';
import { COLORS, GRAYSCALE } from '@emobg/vue-base';
import {
  DATE_FORMAT,
  FALLBACK_MESSAGE,
  reformatDateTime,
  sentenceCase,
} from '@emobg/web-utils';
import { CRM_PERMISSIONS } from '@domains/CRM/const/permissions';
import crm from '@domains/CRM/router/CRMRouterMap';
import PermissionLink from '@/components/PermissionLink/PermissionLink';
import BadgeComponent from '@/components/BadgeComponent/BadgeComponent';
import { duration } from '../../../utils/duration';
import PrebookingCostAllocationComponent from '../components/PrebookingCostAllocationComponent';

export function contentCells({ operatorTimezone } = {}) {
  return [
    {
      title: 'Employee',
      displayPriority: 1,
      minWidth: 140,
      type: RECORD_TYPES.component,
      component: PermissionLink,
      props: result => ({
        classLink: 'emobg-link-primary emobg-body-2',
        to: {
          name: crm.users.detail.account,
          params: {
            userUuid: result.user.uuid,
          },
        },
        linkPermissions: [CRM_PERMISSIONS.viewCrmUsers],
        text: `${result.user.first_name} ${result.user.last_name}`,
      }),
    },
    {
      attributeName: 'start_utc',
      title: 'Start date',
      displayPriority: 1,
      minWidth: 150,
      transformValue: value => reformatDateTime(value, DATE_FORMAT.defaultExtended, operatorTimezone),
    },
    {
      attributeName: 'end_utc',
      title: 'End date',
      displayPriority: 1,
      minWidth: 150,
      transformValue: value => reformatDateTime(value, DATE_FORMAT.defaultExtended, operatorTimezone),
    },
    {
      attributeName: 'booking_uuid',
      title: 'Booking UUID',
      displayPriority: 1,
      minWidth: 300,
      transformValue: value => value || FALLBACK_MESSAGE.dash,
    },
    {
      attributeName: 'location.name',
      title: 'Pick-up location',
      displayPriority: 2,
      minWidth: 200,
    },
    {
      attributeName: 'destination_location.name',
      title: 'Return location',
      displayPriority: 2,
      minWidth: 200,
    },
    {
      attributeName: 'location.city.name',
      title: 'City',
      displayPriority: 2,
      minWidth: 150,
    },
    {
      title: 'Booking duration',
      displayPriority: 2,
      minWidth: 150,
      transformResult: result => duration(result.start, result.end),
    },
    {
      attributeName: 'user.email',
      title: 'Employee email',
      displayPriority: 2,
      minWidth: 250,
    },
    {
      attributeName: 'company_cost_allocations',
      title: 'Cost allocations',
      type: RECORD_TYPES.component,
      displayPriority: 0,
      minWidth: 150,
      component: PrebookingCostAllocationComponent,
      props: result => ({
        costAllocations: result.company_cost_allocations,
      }),
    },
    {
      attributeName: 'cs_booking_use_case.fleet',
      title: 'Fleet type',
      displayPriority: 0,
      minWidth: 115,
      transformValue: sentenceCase,
    },
    {
      attributeName: 'vehicle_category.name',
      title: 'Vehicle category',
      displayPriority: 0,
      minWidth: 100,
    },
    {
      attributeName: 'uuid',
      title: 'Pre-booking UUID',
      displayPriority: 0,
      minWidth: 150,
    },
    {
      attributeName: 'non_connected',
      title: 'Pre-booking type',
      type: RECORD_TYPES.component,
      displayPriority: 0,
      minWidth: 150,
      component: BadgeComponent,
      props: result => ({
        color: result.is_non_connected ? COLORS.warning : GRAYSCALE.ground,
        text: result.is_non_connected ? 'Non-connected' : 'Connected',
        square: true,
      }),
    },
    {
      attributeName: 'comment',
      title: 'Non-connected information',
      type: RECORD_TYPES.template,
      displayPriority: 0,
      minWidth: 100,
      template: result => isEmpty(result.comment) ? FALLBACK_MESSAGE.dash : `<p style="white-space: pre-wrap">${result.comment}</p>`,
    },
  ];
}
