<script>
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import moment from 'moment-timezone';
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import { DATE_FORMAT, DISTANCE_UNIT, sentenceCase } from '@emobg/web-utils';
import {
  MuiAlgoliaSelect,
  MuiInputText,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import { MuiInputDateTime } from '@emobg/motion-ui';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { Cancel } from '@/constants/modalButtons.const';
import {
  GenericModalComponent,
  StoreNotificationComponent,
} from '@/components';
import ContentCell from '@/components/ContentCell/ContentCellComponent';
import BadgeComponent from '@/components/BadgeComponent/BadgeComponent';
import { PATTERN_INPUT_VALIDATIONS } from '@/utils';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { scopes as prebookingScopes } from '../store/PrebookingsModule';
import BookingTypeBadge from '../../components/BookingTypeBadge/BookingTypeBadge';
import { TRIP_TYPES } from '../../const/bookingTypes';
import PrebookingCostAllocationComponent from '../List/components/PrebookingCostAllocationComponent';
import { PREBOOKING_ERRORS } from '../const';

export default {
  name: 'NonConnectedBookingForm',
  components: {
    ContentCell,
    MuiAlgoliaSelect,
    MuiInputText,
    MuiInputDateTime,
    GenericModalComponent,
    MuiValidationWrapper,
    StoreNotificationComponent,
  },
  directives: {
    Validate,
  },
  props: {
    prebooking: {
      type: Object,
      required: true,
    },
    onSuccess: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isFormValid: false,
      realStart: null,
      realEnd: null,
      vehicleUuid: null,
      startMileage: null,
      endMileage: null,
      userUuid: get(this, 'prebooking.user.uuid', null),
      bookingType: get(this, 'prebooking.csBookingUseCase.bookingType', null),
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.carsharing.prebookings, {
      booking: state => state.nonConnectedBooking.data,
      bookingStatus: state => state.nonConnectedBooking.STATUS,
      bookingErrorKey: state => get(state, 'nonConnectedBooking.error.key'),
    }),
    ...mapGetters(DOMAINS_MODEL.app.userAccount, [
      'getOperatorFilter',
      'operatorTimezone',
    ]),
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      mileageUnit: state => get(state, 'operators.mileageUnit', DISTANCE_UNIT.kilometers),
      activeOperatorName: state => state.operators.active.name,
      activeOperatorUuid: state => state.operators.active.uuid,
    }),
    contentCells() {
      const { prebooking } = this;
      const isOneWay = get(prebooking, 'csBookingUseCase.tripType') === TRIP_TYPES.oneWay;
      return [
        {
          label: 'Operator',
          value: get(prebooking, 'csOperatorName', this.activeOperatorName),
        },
        {
          label: 'Pre-booking UUID',
          value: get(prebooking, 'uuid'),
        },
        {
          label: 'User',
          value: `${get(prebooking, 'user.firstName')} ${get(prebooking, 'user.lastName')}`,
        },
        {
          label: 'User profile',
          component: BadgeComponent,
          props: {
            text: capitalize(get(prebooking, 'csBookingUseCase.profile')),
            color: this.GRAYSCALE.ground,
          },
        },
        {
          label: 'Booking type',
          component: BookingTypeBadge,
          props: {
            type: get(prebooking, 'csBookingUseCase.bookingType'),
          },
        },
        {
          label: 'City',
          value: get(prebooking, 'location.city.name'),
        },
        {
          label: 'Start date',
          value: moment(get(prebooking, 'start')).format(DATE_FORMAT.defaultExtended),
        },
        {
          label: 'End date',
          value: moment(get(prebooking, 'end')).format(DATE_FORMAT.defaultExtended),
        },
        {
          label: isOneWay ? 'Pick-up location' : 'Location',
          value: capitalize(get(prebooking, 'location.name')),
        },
        {
          label: 'Return location',
          value: capitalize(get(prebooking, 'destinationLocation.name')),
          isInvisible: !isOneWay,
        },
        {
          label: 'Cost allocations',
          component: PrebookingCostAllocationComponent,
          props: {
            costAllocations: get(prebooking, 'companyCostAllocations', []),
          },
          isInvisible: !get(prebooking, 'companyCostAllocations.length'),
        },
      ];
    },
    validEndDateRange() {
      const minStart = this.realStart;

      return {
        start: minStart,
        end: moment().endOf('minute'),
      };
    },
    modalButtons() {
      return [
        {
          ...Cancel,
          listeners: {
            click: () => this.$emit('closeModal'),
          },
        },
        {
          attributes: {
            form: 'NonConnectedBookingForm',
            type: 'submit',
            loading: this.bookingStatus.LOADING,
            disabled: !this.isFormValid,
          },
          text: 'Create',
        },
      ];
    },
    validateMileage() {
      const message = `End ${this.mileageUnit} should be greater than Start ${this.mileageUnit}.`;
      return { isValid: this.endMileage >= this.startMileage, message };
    },
  },
  watch: {
    realStart: {
      handler(newDate, oldDate) {
        const startDate = moment(newDate);

        if (oldDate && newDate && (oldDate.isSameOrBefore(newDate) && !this.realEnd)) {
          this.realEnd = startDate.add(1, 'minute');
        }

        const endDate = moment(this.realEnd);
        if (this.realEnd && endDate.isSameOrBefore(startDate)) {
          this.realEnd = startDate.add(1, 'minute');
        }
      },
    },
  },
  async created() {
    this.clearData({ scopes: [prebookingScopes.nonConnectedBooking] });
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.PATTERN_INPUT_VALIDATIONS = PATTERN_INPUT_VALIDATIONS;
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.prebookingScopes = prebookingScopes;
    this.validStartDateRange = {
      start: moment().subtract(10, 'years'),
      end: moment().endOf('minute'),
    };
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.carsharing.prebookings, [
      'postNonConnectedBooking',
    ]),
    ...mapMutations(DOMAINS_MODEL.carsharing.prebookings, [
      'clearData',
      'clearErrors',
    ]),
    sentenceCase,
    validateStartDate(newDate) {
      const maxEndDate = moment().endOf('minute');
      if (!newDate.isValid()) {
        this.realStart = moment().startOf('minute');
      } else if (this.realStart && this.realStart.isSameOrAfter(maxEndDate)) {
        this.realStart = maxEndDate;
      }
    },
    validateEndDate(newDate) {
      const endDate = newDate && newDate.isValid() ? moment(newDate) : moment();
      const minEndDate = get(this, 'validEndDateRange.start');
      const maxEndDate = moment().endOf('minute');

      this.$nextTick(() => {
        if (endDate && minEndDate && endDate.isSameOrBefore(minEndDate)) {
          this.realEnd = moment(minEndDate).add(1, 'minute');
        } else if (endDate && maxEndDate && endDate.isSameOrAfter(maxEndDate)) {
          this.realEnd = maxEndDate;
        } else {
          this.realEnd = newDate;
        }
      });
    },
    dateForRequest(momentDate) {
      const momentTimezoned = moment.tz(momentDate.format(DATE_FORMAT.filter), this.operatorTimezone);
      return momentTimezoned.utc().format(DATE_FORMAT.filter);
    },
    async request() {
      this.clearErrors({ scope: prebookingScopes.nonConnectedBooking });
      const { prebooking } = this;
      await this.postNonConnectedBooking({
        csOperatorUuid: this.activeOperatorUuid,
        preBookingUuid: get(prebooking, 'uuid'),
        userUuid: get(prebooking, 'user.uuid'),
        userProfileType: get(prebooking, 'csBookingUseCase.profile'),
        bookingType: get(prebooking, 'csBookingUseCase.bookingType'),
        insuranceUuid: get(prebooking, 'insuranceUuid'),
        start: get(prebooking, 'startUtc'),
        end: get(prebooking, 'endUtc'),
        companyCostAllocations: get(prebooking, 'companyCostAllocations', []),
        locationUuid: get(prebooking, 'location.uuid'),
        returnLocationUuid: get(prebooking, 'destinationLocation.uuid', null),
        vehicleUuid: this.vehicleUuid,
        realStart: this.dateForRequest(this.realStart),
        realEnd: this.dateForRequest(this.realEnd),
        kmStart: this.startMileage,
        kmEnd: this.endMileage,
      });

      if (!this.bookingStatus.ERROR) {
        const { id, uuid } = this.booking;
        const linkNode = `<a class="emobg-link-primary emobg-body-2" href="/carsharing/bookings/${uuid}" target="_blank">${id}</a>`;
        const message = `The non-connected booking with Id ${linkNode} was created succesfully.`;
        this.$notify({
          message,
          textAction: 'Dismiss',
        });
        this.onSuccess();
        this.clearData({ scopes: [prebookingScopes.nonConnectedBooking] });
        this.$emit('closeModal');
      } else if (this.bookingErrorKey !== PREBOOKING_ERRORS.bookingCannotBeCreated) {
        this.clearData({ scopes: [prebookingScopes.nonConnectedBooking] });
        this.$emit('closeModal');
      }
    },
  },
};
</script>

<template>
  <GenericModalComponent
    :header="{ isClosable: true }"
    :buttons="modalButtons"
    title="Create Non-connected booking"
    class="NonConnectedBookingForm"
    data-test-id="non_connected_booking-form"
    v-on="$listeners"
  >
    <template #alerts>
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.carsharing.prebookings"
        :scope="prebookingScopes.nonConnectedBooking"
        action="Create non-connected booking"
        data-test-id="alert"
      />
    </template>
    <template #body>
      <div class="row">
        <div class="col-12 mb-4 emobg-caption-1 emobg-color-ink-light">
          Non-connected bookings will be created as Finished bookings and can only be created in the past.
        </div>
        <ContentCell
          v-for="(contentCell, index) in contentCells"
          :key="index"
          :label="contentCell.label"
          :class="['col-md-6 mb-4', { 'd-none': contentCell.isInvisible }]"
          :data-test-id="`cell_${contentCell.label}`"
        >
          <Component
            :is="contentCell.component"
            v-if="contentCell.component"
            v-bind="contentCell.props"
          />
          <template v-else>
            {{ contentCell.value }}
          </template>
        </ContentCell>
      </div>
      <MuiValidationWrapper
        id="NonConnectedBookingForm"
        data-test-id="form"
        :extra-conditions="[validateMileage.isValid]"
        @areAllValid="valid => isFormValid = valid"
        @submit="request"
      >
        <div class="row">
          <MuiAlgoliaSelect
            v-model="vehicleUuid"
            v-validate="{
              isRequired: true,
            }"
            :filters="`${getOperatorFilter({ index: 'vehicles' })} AND non_connected:1`"
            :title="result => `${result.license_plate} - ${result.vehicle_brand} ${result.vehicle_model}`"
            :placeholder="$t('Common.Actions.select_element', { element: $t('Common.Business.vehicle') })"
            :index="ALGOLIA_INDEXES.vehicles"
            label="Non-connected vehicle*"
            path-value="uuid"
            class="col-12 mb-4"
            name="vehicleUuid"
            data-test-id="vehicle-select"
          />

          <div class="col-sm-6 mb-4">
            <label class="d-block emobg-font-weight-semibold mb-1">
              Real start date*
            </label>
            <MuiInputDateTime
              v-model="realStart"
              v-validate="{
                isRequired: true,
              }"
              :valid-date-range="validStartDateRange"
              :minutes-range="1"
              name="start"
              size="s"
              data-test-id="start_date-input"
              @input="validateStartDate"
            />
          </div>
          <div class="col-sm-6 mb-4">
            <label :class="['d-block emobg-font-weight-semibold mb-1', {'disabled': !realStart}]">
              Real end date*
            </label>
            <MuiInputDateTime
              v-validate="{
                isRequired: true,
              }"
              :value="realEnd"
              :valid-date-range="validEndDateRange"
              :disabled="!realStart"
              :minutes-range="1"
              size="s"
              data-test-id="end_date-input"
              @input="validateEndDate"
            />
          </div>

          <div class="col-sm-6 mb-1">
            <MuiInputText
              v-model.number="startMileage"
              v-validate="{
                isRequired: true,
                isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,
              }"
              :label="`Start ${mileageUnit}*`"
              :placeholder="`Enter a number value for ${mileageUnit}`"
              name="startMileage"
              data-test-id="start_mileage-input"
            />
          </div>
          <div class="col-sm-6 mb-1">
            <MuiInputText
              v-model.number="endMileage"
              v-validate="{
                isRequired: true,
                isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,
              }"
              :label="`End ${mileageUnit}*`"
              :placeholder="`Enter a number value for ${mileageUnit}`"
              name="endMileage"
              data-test-id="end_mileage-input"
            />
          </div>
        </div>
      </MuiValidationWrapper>
    </template>
  </GenericModalComponent>
</template>
