var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "NonConnectedBookingForm",
        attrs: {
          header: { isClosable: true },
          buttons: _vm.modalButtons,
          title: "Create Non-connected booking",
          "data-test-id": "non_connected_booking-form",
        },
        scopedSlots: _vm._u([
          {
            key: "alerts",
            fn: function () {
              return [
                _c("StoreNotificationComponent", {
                  attrs: {
                    "store-domain": _vm.DOMAINS_MODEL.carsharing.prebookings,
                    scope: _vm.prebookingScopes.nonConnectedBooking,
                    action: "Create non-connected booking",
                    "data-test-id": "alert",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-12 mb-4 emobg-caption-1 emobg-color-ink-light",
                      },
                      [
                        _vm._v(
                          " Non-connected bookings will be created as Finished bookings and can only be created in the past. "
                        ),
                      ]
                    ),
                    _vm._l(_vm.contentCells, function (contentCell, index) {
                      return _c(
                        "ContentCell",
                        {
                          key: index,
                          class: [
                            "col-md-6 mb-4",
                            { "d-none": contentCell.isInvisible },
                          ],
                          attrs: {
                            label: contentCell.label,
                            "data-test-id": `cell_${contentCell.label}`,
                          },
                        },
                        [
                          contentCell.component
                            ? _c(
                                contentCell.component,
                                _vm._b(
                                  { tag: "Component" },
                                  "Component",
                                  contentCell.props,
                                  false
                                )
                              )
                            : [_vm._v(" " + _vm._s(contentCell.value) + " ")],
                        ],
                        2
                      )
                    }),
                  ],
                  2
                ),
                _c(
                  "MuiValidationWrapper",
                  {
                    attrs: {
                      id: "NonConnectedBookingForm",
                      "data-test-id": "form",
                      "extra-conditions": [_vm.validateMileage.isValid],
                    },
                    on: {
                      areAllValid: (valid) => (_vm.isFormValid = valid),
                      submit: _vm.request,
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c("MuiAlgoliaSelect", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: {
                                isRequired: true,
                              },
                              expression:
                                "{\n            isRequired: true,\n          }",
                            },
                          ],
                          staticClass: "col-12 mb-4",
                          attrs: {
                            filters: `${_vm.getOperatorFilter({
                              index: "vehicles",
                            })} AND non_connected:1`,
                            title: (result) =>
                              `${result.license_plate} - ${result.vehicle_brand} ${result.vehicle_model}`,
                            placeholder: _vm.$t(
                              "Common.Actions.select_element",
                              { element: _vm.$t("Common.Business.vehicle") }
                            ),
                            index: _vm.ALGOLIA_INDEXES.vehicles,
                            label: "Non-connected vehicle*",
                            "path-value": "uuid",
                            name: "vehicleUuid",
                            "data-test-id": "vehicle-select",
                          },
                          model: {
                            value: _vm.vehicleUuid,
                            callback: function ($$v) {
                              _vm.vehicleUuid = $$v
                            },
                            expression: "vehicleUuid",
                          },
                        }),
                        _c(
                          "div",
                          { staticClass: "col-sm-6 mb-4" },
                          [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "d-block emobg-font-weight-semibold mb-1",
                              },
                              [_vm._v(" Real start date* ")]
                            ),
                            _c("MuiInputDateTime", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: {
                                    isRequired: true,
                                  },
                                  expression:
                                    "{\n              isRequired: true,\n            }",
                                },
                              ],
                              attrs: {
                                "valid-date-range": _vm.validStartDateRange,
                                "minutes-range": 1,
                                name: "start",
                                size: "s",
                                "data-test-id": "start_date-input",
                              },
                              on: { input: _vm.validateStartDate },
                              model: {
                                value: _vm.realStart,
                                callback: function ($$v) {
                                  _vm.realStart = $$v
                                },
                                expression: "realStart",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-6 mb-4" },
                          [
                            _c(
                              "label",
                              {
                                class: [
                                  "d-block emobg-font-weight-semibold mb-1",
                                  { disabled: !_vm.realStart },
                                ],
                              },
                              [_vm._v(" Real end date* ")]
                            ),
                            _c("MuiInputDateTime", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: {
                                    isRequired: true,
                                  },
                                  expression:
                                    "{\n              isRequired: true,\n            }",
                                },
                              ],
                              attrs: {
                                value: _vm.realEnd,
                                "valid-date-range": _vm.validEndDateRange,
                                disabled: !_vm.realStart,
                                "minutes-range": 1,
                                size: "s",
                                "data-test-id": "end_date-input",
                              },
                              on: { input: _vm.validateEndDate },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-6 mb-1" },
                          [
                            _c("MuiInputText", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: {
                                    isRequired: true,
                                    isPattern:
                                      _vm.PATTERN_INPUT_VALIDATIONS.wholeNumber,
                                  },
                                  expression:
                                    "{\n              isRequired: true,\n              isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,\n            }",
                                },
                              ],
                              attrs: {
                                label: `Start ${_vm.mileageUnit}*`,
                                placeholder: `Enter a number value for ${_vm.mileageUnit}`,
                                name: "startMileage",
                                "data-test-id": "start_mileage-input",
                              },
                              model: {
                                value: _vm.startMileage,
                                callback: function ($$v) {
                                  _vm.startMileage = _vm._n($$v)
                                },
                                expression: "startMileage",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-6 mb-1" },
                          [
                            _c("MuiInputText", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: {
                                    isRequired: true,
                                    isPattern:
                                      _vm.PATTERN_INPUT_VALIDATIONS.wholeNumber,
                                  },
                                  expression:
                                    "{\n              isRequired: true,\n              isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,\n            }",
                                },
                              ],
                              attrs: {
                                label: `End ${_vm.mileageUnit}*`,
                                placeholder: `Enter a number value for ${_vm.mileageUnit}`,
                                name: "endMileage",
                                "data-test-id": "end_mileage-input",
                              },
                              model: {
                                value: _vm.endMileage,
                                callback: function ($$v) {
                                  _vm.endMileage = _vm._n($$v)
                                },
                                expression: "endMileage",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }