var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PageView",
    {
      staticClass: "PrebookingsListView",
      attrs: { "data-test-id": "prebookings_list-view" },
    },
    [
      _c("h1", { staticClass: "mb-3" }, [_vm._v(" Pre-bookings ")]),
      _c(
        "div",
        { staticClass: "position-relative" },
        [
          _vm.isLoading
            ? _c("ui-loader", { attrs: { absolute: "" } })
            : _vm._e(),
          _c("MuiAlgoliaList", {
            ref: "prebookings",
            attrs: {
              index: _vm.ALGOLIA_INDEXES.prebookings,
              filters: _vm.getOperatorFilter({
                attribute: "cs_operator_uuid",
                searchUsing: "uuid",
              }),
              "item-actions": _vm.itemsActions,
              "export-columns": _vm.contentCells,
              facets: _vm.facets,
              labels: {
                searchTable: "Search pre-bookings...",
              },
              "table-config": _vm.contentCells,
              "empty-message": _vm.getNoResultsLabel("pre-bookings"),
              "is-export-enabled": "",
              "data-test-id": "list",
            },
          }),
        ],
        1
      ),
      _vm.isModalVisible
        ? _c("MarkPrebookingAsNonConnectedForm", {
            attrs: {
              prebooking: _vm.prebooking,
              "on-success": _vm.onFormSuccess,
            },
            on: {
              closeModal: function ($event) {
                _vm.isModalVisible = false
              },
            },
          })
        : _vm._e(),
      _vm.isBookingFormVisible
        ? _c("NonConnectedBookingForm", {
            attrs: {
              prebooking: _vm.prebooking,
              "on-success": _vm.onFormSuccess,
            },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }