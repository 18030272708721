<script>
import first from 'lodash/first';
import get from 'lodash/get';
import join from 'lodash/join';
import map from 'lodash/map';
import size from 'lodash/size';
import {
  mapGetters,
  mapMutations,
} from 'vuex';
import { camelCaseKeys, DELAY, isNullString } from '@emobg/web-utils';
import { MuiAlgoliaList } from '@emobg/motion-ui';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { PageView } from '@/components';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { getNoResultsLabel, refreshAlgoliaStore } from '@/utils/algolia';
import { scopes as prebookingsScope } from '../store/PrebookingsModule';
import {
  contentCells,
  facets,
} from './config/index';
import MarkPrebookingAsNonConnectedForm from '../components/MarkPrebookingAsNonConnectedForm';
import NonConnectedBookingForm from '../components/NonConnectedBookingForm';

export default {
  name: 'PrebookingsListView',
  components: {
    MarkPrebookingAsNonConnectedForm,
    NonConnectedBookingForm,
    MuiAlgoliaList,
    PageView,
  },
  data() {
    return {
      isLoading: false,
      isModalVisible: false,
      isBookingFormVisible: false,
      prebooking: null,
    };
  },
  computed: {
    ...mapGetters(DOMAINS_MODEL.app.userAccount, [
      'getOperatorFilter',
      'operatorTimezone',
      'activeOperator',
    ]),
    contentCells() {
      return contentCells({ operatorTimezone: this.operatorTimezone });
    },
    facets() {
      return facets({ operatorTimezone: this.operatorTimezone });
    },
    operatorChildrenCompanyUuids() {
      return map(this.activeOperator.children, 'company_uuid');
    },
  },
  watch: {
    async activeOperator() {
      await this.fetchCompanyCostAllocations();
    },
  },
  created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.itemsActions = [
      {
        label: 'Create non-connected booking',
        method: prebooking => {
          this.prebooking = camelCaseKeys(prebooking);
          this.isBookingFormVisible = true;
        },
        isVisible: result => result.is_non_connected && isNullString(result.booking_uuid),
      },
      {
        label: ({ is_non_connected: isNonConnected }) => (isNonConnected ? 'Edit non-connected information' : 'Mark as non-connected'),
        method: prebooking => {
          this.prebooking = camelCaseKeys(prebooking);
          this.isModalVisible = true;
        },
        isVisible: result => isNullString(result.booking_uuid),
      },
    ];
  },
  async mounted() {
    await this.fetchCompanyCostAllocations();
  },
  methods: {
    getNoResultsLabel,
    ...mapMutations(DOMAINS_MODEL.carsharing.prebookings, {
      setPrebookingsData: 'setData',
    }),
    closeModal() {
      this.isModalVisible = false;
      this.isBookingFormVisible = false;
      this.prebooking = null;
    },
    onFormSuccess() {
      this.isLoading = true;
      refreshAlgoliaStore(this.$refs.prebookings, DELAY.extraLong, () => { this.isLoading = false; });
    },
    async fetchCompanyCostAllocations() {
      const algoliaActiveOperatorResponse = await this.$algolia.fetchIndex(ALGOLIA_INDEXES.csOperators, {
        filters: `uuid:${this.activeOperator.uuid}`,
      });
      const algoliaActiveOperator = first(algoliaActiveOperatorResponse.hits);
      const filterOperatorChildrenCompanyUuids = size(this.operatorChildrenCompanyUuids)
        ? ` OR company_uuid:${join(this.operatorChildrenCompanyUuids, ' OR company_uuid:')}`
        : '';
      const parentCostAllocations = await this.$algolia.fetchIndex(ALGOLIA_INDEXES.companyCostAllocations, {
        filters: `parent_uuid:'null' AND (company_uuid:${get(algoliaActiveOperator, 'company_uuid', 'null')}${filterOperatorChildrenCompanyUuids})`,
      });
      if (parentCostAllocations) {
        this.setPrebookingsData({
          scope: prebookingsScope.costAllocations,
          value: parentCostAllocations.hits,
        });
      }
    },
  },
};
</script>

<template>
  <PageView
    class="PrebookingsListView"
    data-test-id="prebookings_list-view"
  >
    <h1 class="mb-3">
      Pre-bookings
    </h1>
    <div class="position-relative">
      <ui-loader
        v-if="isLoading"
        absolute
      />
      <MuiAlgoliaList
        ref="prebookings"
        :index="ALGOLIA_INDEXES.prebookings"
        :filters="getOperatorFilter({ attribute: 'cs_operator_uuid', searchUsing: 'uuid' })"
        :item-actions="itemsActions"
        :export-columns="contentCells"
        :facets="facets"
        :labels="{
          searchTable: 'Search pre-bookings...',
        }"
        :table-config="contentCells"
        :empty-message="getNoResultsLabel('pre-bookings')"
        is-export-enabled
        data-test-id="list"
      />
    </div>
    <MarkPrebookingAsNonConnectedForm
      v-if="isModalVisible"
      :prebooking="prebooking"
      :on-success="onFormSuccess"
      @closeModal="isModalVisible = false"
    />
    <NonConnectedBookingForm
      v-if="isBookingFormVisible"
      :prebooking="prebooking"
      :on-success="onFormSuccess"
      @closeModal="closeModal"
    />
  </PageView>
</template>
