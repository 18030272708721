import moment from 'moment-timezone';
import { PRECISION, formatStopWatch } from '@emobg/web-utils';

export const duration = (start, end) => {
  if (!start || !end) {
    return '';
  }

  const durationSeconds = moment(end).diff(start, 'seconds');

  return formatStopWatch(durationSeconds, PRECISION.hours);
};
