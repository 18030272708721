var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "PrebookingCostAllocationComponent" },
    [
      _vm.costAllocationsWithParentName.length
        ? _vm._l(
            _vm.costAllocationsWithParentName,
            function (costAllocation, index) {
              return _c(
                "div",
                { key: costAllocation.uuid, class: { "mt-1": index } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        costAllocation.parentName
                          ? `${costAllocation.parentName}: `
                          : ""
                      ) +
                      _vm._s(costAllocation.value) +
                      " "
                  ),
                ]
              )
            }
          )
        : _c("div", [_vm._v(" " + _vm._s(_vm.FALLBACK_MESSAGE.dash) + " ")]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }