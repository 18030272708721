import { isFalsyString, sentenceCase } from '@emobg/web-utils';

export { contentCells } from './contentCells';

export const facets = ({ operatorTimezone }) => [
  {
    type: 'DateIntervalsRange',
    props: {
      title: 'Dates',
      dateStart: 'start_ts',
      dateEnd: 'end_ts',
      labels: {
        from: 'From',
        to: 'To',
        setToday: 'Set to today',
        none: 'None',
        rangeOverlap: 'Active Bookings',
        dateEnd: 'Pre-booking end date',
        dateStart: 'Pre-booking start date',
        infoNone: 'No date filters applied',
        infoRangeOverlap: 'Pre-booking being active (in progress) in selected period',
        infoDateStart: 'Pre-booking starting in selected period',
        infoDateEnd: 'Pre-booking ending in selected period',
      },
      operatorTimezone,
      from: null,
      to: null,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Operator',
      attributeName: 'cs_operator_name',
      toggled: true,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'City',
      attributeName: 'location.city.name',
      toggled: true,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Pick-up location',
      attributeName: 'location.name',
      toggled: true,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Return location',
      attributeName: 'destination_location.name',
      toggled: true,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Fleet type',
      attributeName: 'cs_booking_use_case.fleet',
      toggled: true,
      transformValue: sentenceCase,
    },
  },
  {
    type: 'refinementOptions',
    props: {
      title: 'Vehicle assigned (Booking UUID)',
      attributeName: 'booking_uuid',
      showTooltip: false,
      options: [
        {
          label: 'Yes',
          value: '-null',
        },
        {
          label: 'No',
          value: 'null',
        },
      ],
      toggled: true,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Pre-booking type',
      attributeName: 'is_non_connected',
      transformValue: value => (isFalsyString(value) ? 'Connected' : 'Non-connected'),
    },
  },
];
