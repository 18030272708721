<script>
import find from 'lodash/find';
import get from 'lodash/get';
import map from 'lodash/map';
import { mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { camelCaseKeys } from '@emobg/web-utils';

export default {
  name: 'PrebookingCostAllocationComponent',
  props: {
    costAllocations: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.carsharing.prebookings, {
      parentCostAllocations: state => state.costAllocations.data,
    }),
    costAllocationsWithParentName() {
      return map(this.costAllocations, costAllocation => {
        const parentUuid = costAllocation.parent_uuid || costAllocation.parentUuid;
        const parentCostAllocation = find(this.parentCostAllocations, { uuid: parentUuid });
        return {
          ...camelCaseKeys(costAllocation),
          parentName: get(parentCostAllocation, 'name'),
        };
      });
    },
  },
};
</script>

<template>
  <div class="PrebookingCostAllocationComponent">
    <template v-if="costAllocationsWithParentName.length">
      <div
        v-for="(costAllocation, index) in costAllocationsWithParentName"
        :key="costAllocation.uuid"
        :class="{ 'mt-1': index }"
      >
        {{ costAllocation.parentName ? `${costAllocation.parentName}: ` : '' }}{{ costAllocation.value }}
      </div>
    </template>
    <div v-else>
      {{ FALLBACK_MESSAGE.dash }}
    </div>
  </div>
</template>
