<script>
import { mapActions, mapState } from 'vuex';
import {
  MuiTextarea,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { Cancel } from '@/constants/modalButtons.const';
import {
  GenericModalComponent,
  StoreNotificationComponent,
} from '@/components';
import { scopes as prebookingScopes } from '../store/PrebookingsModule';

export default {
  name: 'MarkPrebookingAsNonConnectedForm',
  components: {
    GenericModalComponent,
    MuiTextarea,
    MuiValidationWrapper,
    StoreNotificationComponent,
  },
  directives: {
    Validate,
  },
  props: {
    prebooking: {
      type: Object,
      required: true,
    },
    onSuccess: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isFormValid: false,
      isLoading: false,
      inputs: {
        comment: '',
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.carsharing.prebookings, {
      markAsNonConnectedStatus: state => state[prebookingScopes.markAsNonConnected].STATUS,
    }),
    modalTitle() {
      return this.prebooking.isNonConnected
        ? 'Edit information <span class="emobg-font-default emobg-font-line-height-default">(non-connected pre-booking)</span>'
        : 'Mark pre-booking as non-connected';
    },
    modalButtons() {
      return [
        {
          ...Cancel,
          listeners: {
            click: () => this.$emit('closeModal'),
          },
        },
        {
          attributes: {
            form: 'markPrebookingAsNonConnectedForm',
            type: 'submit',
            loading: this.isLoading,
            disabled: !this.isFormValid,
          },
          text: 'Save',
        },
      ];
    },
  },
  created() {
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.prebookingScopes = prebookingScopes;

    if (this.prebooking) {
      this.inputs.comment = this.prebooking.comment;
    }
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.carsharing.prebookings, ['markAsNonConnected']),
    async request() {
      this.isLoading = true;
      await this.markAsNonConnected({ prebookingUuid: this.prebooking.uuid, data: this.inputs });
      this.isLoading = false;

      if (!this.markAsNonConnectedStatus.ERROR) {
        const message = this.prebooking.isNonConnected
          ? 'Information updated succesfully. The list will be sync in a few seconds'
          : 'Pre-booking marked succesfully as non-connected. The list will be sync in a few seconds';
        this.$notify({
          message,
          textAction: '',
        });
        this.onSuccess();
        this.$emit('closeModal');
      }
    },
  },
};
</script>

<template>
  <GenericModalComponent
    :header="{ isClosable: true }"
    :size="SIZES.small"
    :buttons="modalButtons"
    class="MarkPrebookingAsNonConnectedForm"
    data-test-id="mark_as_non_connected-form"
    v-on="$listeners"
  >
    <div
      slot="header"
      data-test-id="header"
      v-html="modalTitle"
    />
    <template #alerts>
      <StoreNotificationComponent
        :action="prebooking.isNonConnected ? 'edit information' : 'mark as non connected'"
        :store-domain="DOMAINS_MODEL.carsharing.prebookings"
        :scope="prebookingScopes.markAsNonConnected"
        data-test-id="alert"
      />
    </template>
    <template #body>
      <MuiValidationWrapper
        id="markPrebookingAsNonConnectedForm"
        data-test-id="form"
        @areAllValid="valid => isFormValid = valid"
        @submit="request"
      >
        <p
          v-if="!prebooking.isNonConnected"
          class="mb-4"
        >
          After a pre-booking is marked as non-connected the action cannot be undone.
        </p>
        <MuiTextarea
          v-model="inputs.comment"
          v-validate="{
            isRequired: true,
            isMinLength: {
              message: 'We need at least 6 characters',
              length: 6,
            }
          }"
          label="Information*"
          name="comments"
          placeholder="Write all the information needed to inform the user about the booking."
          rows="4"
          data-test-id="comment_input"
        />
      </MuiValidationWrapper>
    </template>
  </GenericModalComponent>
</template>
